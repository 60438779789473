[data-theme=light] .navbar-home img {
  max-height: 40px !important;
  width: auto;
}
[data-theme=light] .sidebar-toggle-btn {
  --icon-stroke: #ffffff !important;
}
[data-theme=light] h3 {
  color: #ffffff !important;
}
[data-theme=light] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: none;
}
[data-theme=light] .navbar {
  height: 48px;
  background: #E2E2E2 !important;
  border-bottom: 1px solid var(--border-color);
  padding: 0;
  color: black;
}
[data-theme=light] .page-head {
  z-index: 6;
  position: sticky;
  top: var(--navbar-height);
  background: #252C58 !important;
  margin-bottom: 35px;
  transition: 0.5s top;
}
[data-theme=light] .page-title .title-area .title-text {
  color: white !important;
}
[data-theme=light] .btn-primary {
  background-color: #E52579 !important;
}
[data-theme=light] a {
  margin-right: 10px;
  font-size: var(--text-lg);
}
[data-theme=light] .badge-link {
  color: black !important;
}
[data-theme=light] .d-none {
  color: black !important;
}
[data-theme=light] .layout-main-section.frappe-card {
  overflow: hidden;
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  padding: 0px;
  background-color: var(--card-bg);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color);
}
[data-theme=light] .page-form .standard-filter-section {
  flex-wrap: wrap;
  flex: 1;
  gap: 10px !important;
}
[data-theme=light] .search-bar .awesomplete input {
  width: 100% !important;
  padding: 3px 32px !important;
  height: 28px;
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}
[data-theme=light] .form-control {
  border: 2px solid #D5D9DD !important;
  color: #252C58 !important;
  height: var(--input-height);
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  border-radius: 6px !important;
  padding: 0px 4px !important;
  position: relative;
  background: white !important;
}
[data-theme=light] .control-value {
  border: 2px solid #D5D9DD !important;
  color: #252C58 !important;
  height: unset !important;
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  border-radius: 6px !important;
  padding: 1px 7px !important;
  position: relative;
  background: white !important;
}
[data-theme=light] .search-box .search-input[data-v-eb973a79] {
  padding-left: 30px !important;
}
[data-theme=light] .list-row .list-row-activity .comment-count {
  min-width: 35px;
  display: none !important;
}
[data-theme=light] .like-action, [data-theme=light] .not-liked {
  display: none !important;
}
[data-theme=light] .mx-2 {
  display: none !important;
}
[data-theme=light] .list-row-col {
  flex: 1;
  margin-right: 15px;
}
[data-theme=light] .no-list-sidebar[data-page-route^="List/"] .layout-main-section-wrapper, [data-theme=light] .no-list-sidebar [data-page-route^="List/"] .layout-main-section-wrapper {
  flex: 1;
  max-width: 100%;
}
[data-theme=light] .d-lg-block {
  display: none !important;
}
[data-theme=light] .frappe-control[data-fieldtype=Icon] input {
  padding-left: 40px !important;
}
[data-theme=light] .frappe-control[data-fieldtype=Select].frappe-control[data-fieldname=color] .color-select {
  padding-left: 40px !important;
}
[data-theme=light] .icon-picker .search-icons input[type=search] {
  height: inherit;
  padding-left: 30px !important;
}
[data-theme=light] .row-check {
  height: 45px !important;
  text-align: center;
}
[data-theme=light] .grid-static-col, [data-theme=light] .row-index {
  height: 45px !important;
  max-height: 200px;
}
[data-theme=light] .menu-btn-group, [data-theme=light] .custom-btn-group, [data-theme=light] .page-icon-group {
  display: none !important;
}
[data-theme=light] .standard-sidebar-label {
  display: none !important;
}
[data-theme=light] .file-uploader .file-uploader-area button {
  display: none;
}
[data-theme=light] .file-uploader .file-uploader-area button:first-child {
  display: block;
}
[data-theme=light] .file-uploader button:not(:first-child) {
  display: none;
}
[data-theme=light] .modal .modal-footer .standard-actions button:not(:last-child) {
  margin-right: var(--margin-xs);
  display: none !important;
}
[data-theme=light] [data-route="Workspaces/Home Dashboard"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Masters"] .widget {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  padding: 7px;
  border-radius: var(--border-radius-tiny);
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color);
}
[data-theme=light] [data-route="Workspaces/Masters"] .widget.quick-list-widget-box .widget-footer .see-all {
  width: 100%;
  text-decoration: none;
  background-color: aqua !important;
}
[data-theme=light] [data-route="Workspaces/Masters"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Ex-Serviceman List"] .widget {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  padding: 7px;
  border-radius: var(--border-radius-tiny);
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color);
}
[data-theme=light] [data-route="Workspaces/Ex-Serviceman List"] .widget.quick-list-widget-box .widget-footer .see-all {
  width: 100%;
  text-decoration: none;
  background-color: aqua !important;
}
[data-theme=light] [data-route="Workspaces/Ex-Serviceman List"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Client List"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Job List"] .widget {
  display: flex;
  flex-direction: column;
  min-height: 1px;
  padding: 7px;
  border-radius: var(--border-radius-tiny);
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color);
}
[data-theme=light] [data-route="Workspaces/Job List"] .widget.quick-list-widget-box .widget-footer .see-all {
  width: 100%;
  text-decoration: none;
  background-color: aqua !important;
}
[data-theme=light] [data-route="Workspaces/Job List"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Payroll"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-route="Workspaces/Users"] .layout-side-section {
  font-size: var(--text-sm);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
  padding-right: 30px;
  display: block !important;
}
[data-theme=light] [data-page-route=Workspaces] .widget.number-widget-box {
  border-radius: var(--border-radius-tiny);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color) !important;
}
[data-theme=light] .widget.dashboard-widget-box {
  min-height: 240px;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1490196078) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: var(--border-radius-tiny);
}
[data-theme=light] .widget.number-widget-box .widget-head .widget-title {
  font-weight: var(--weight-medium);
  color: var(--gray-900) !important;
  text-transform: uppercase;
  font-size: var(--text-base) !important;
  margin-top: var(--margin-xs);
}
[data-theme=light] [data-fieldname=serviceman_document_table] .grid-body [data-fieldname=document_attach] .field-area {
  display: block !important;
}
[data-theme=light] [data-fieldname=serviceman_document_table] .grid-body [data-fieldname=document_attach] .static-area {
  display: none !important;
}
[data-theme=light] [data-fieldname=serviceman_document_table] .grid-body [data-fieldtype=Attach] .attached-file, [data-theme=light] [data-fieldname=serviceman_document_table] .grid-body [data-fieldtype="Attach Image"] .attached-file {
  position: relative;
  padding: 0px 5px !important;
  background: none !important;
  border-radius: var(--border-radius);
  font-size: var(--text-base);
  font-weight: var(--weight-regular);
  letter-spacing: 0.02em;
}

.file-uploader .file-uploader-area button {
  display: none;
}
.file-uploader .file-uploader-area button:first-child {
  display: block;
}

.file-uploader button:not(:first-child) {
  display: none;
}

.modal .modal-footer .standard-actions button:not(:last-child) {
  margin-right: var(--margin-xs);
  background-color: rgba(49, 59, 68, 0.73);
}